<template>
    <r-e-dialog title="操作记录" :visible.sync="dialogVisible" top="5vh" width="900px" :show-footer="false">
<!--        <div class="flex" style="background-color: #EFF9FF;height: 40px;justify-content: space-between;align-items: center;color: #333333;font-size: 15px;">-->
<!--            <div style="margin-left: 20px">-->
<!--                房间号 : {{equipmentData.name}}-->
<!--            </div>-->
<!--            <div style="margin-right: 20px">-->
<!--                门锁号: {{equipmentData.lockId}}-->
<!--            </div>-->
<!--        </div>-->
<!--        <div>-->
<!--            <el-select size="small" v-model="type" placeholder="请选择">-->
<!--                <el-option label="全部" value=""/>-->
<!--                <el-option label="密码开门" value="4"/>-->
<!--&lt;!&ndash;                <el-option label="钥匙开门" value="EVENT_KEY_ACTION"/>&ndash;&gt;-->
<!--&lt;!&ndash;                <el-option label="临时密码开门" value="EVENT_TOKEN_ACTION"/>&ndash;&gt;-->
<!--&lt;!&ndash;                <el-option label="内把手开门" value="EVENT_INNER_HANDLE_ACTION"/>&ndash;&gt;-->
<!--&lt;!&ndash;                <el-option label="关门" value="EVENT_CLOSE"/>&ndash;&gt;-->
<!--            </el-select>-->
<!--            <el-date-picker v-model="dateArr" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"-->
<!--                            size="small" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>-->
<!--            <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>-->
<!--        </div>-->
        <r-e-table ref="communityTableRef" :columns="tableoperatingRecord" :dataRequest="getFamilyList" :query="formSearch" :height="300">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </r-e-dialog>
</template>

<script>
    import {tableoperatingRecord} from "@/views/property-management/door-management/data";
    import {openLocksLog, tableoperatingRecordapi} from "@/api/door-management"

    export default {
        name: "dialog-show-unlock-record",
        data() {
            return {
                dialogVisible: false,
                dataList: [],
              tableoperatingRecord,
                type: '',
                uuid: '',
                dateArr: [],
                formSearch: {}
            }
        },
        props: {
            equipmentData: {
                type: Object,
                default: () => ({})
            }
        },
        components: {},
        methods: {
            openDialog(data) {
                this.uuid = data;
                this.dialogVisible = true;
            },
            getFamilyList(params) {
                let {uuid} = this;
                // params.type = this.type;
                if (this.dateArr && this.dateArr.length !== 0) {
                    let [startDate, endDate] = this.dateArr;
                    params.startDate = startDate;
                    params.endDate = endDate;
                }
                console.log({uuid, ...params})
                return tableoperatingRecordapi({uuid, ...params});
            },
            handleSearch() {
                this.$refs["communityTableRef"].pNumber = 1;
                this.$refs["communityTableRef"].getTableData();
            }
        },
    }
</script>

<style scoped>

</style>